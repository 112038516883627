<template>
  <div class="main-wrapper">
    <section
      class="main_banner_area_winapp text-white"
      style="min-height: 100px"
    >
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <div class="info">
              <h1 class="display-4 mb-3 bold">
                <i class="icon-cloud-download"></i> Downloads
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="faq">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <!-- Heading Text  -->
            <div class="section-heading text-center">
              <h2>Download Document Feature.</h2>
              <div class="line-title-center"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <div id="accordion" role="tablist">
              <div class="card">
                <div class="card-header heading" role="tab">
                  <h6 class="mb-0">
                    <a
                      href="#collapse1"
                      data-parent="#accordion"
                      data-toggle="collapse"
                    >
                      1. Biosoft Feature.
                      <i class="icon-arrow-down float-right"></i>
                    </a>
                  </h6>
                </div>

                <div id="collapse1" class="collapse">
                  <div class="card-body">
                    <i class="icon-cloud-download"></i>&nbsp;<a
                      style="cursor: pointer;"
                      @click="loadDocument"
                      >Download</a
                    >
                    <div class="line-title-left" style="margin-top: 5px;"></div>
                  </div>
                </div>
              </div>

              <!-- <div class="card">
                <div class="card-header heading" role="tab">
                  <h6 class="mb-0">
                    <a
                      href="#collapse2"
                      data-parent="#accordion"
                      data-toggle="collapse"
                    >
                      2. Biosoft Mobile Feature.
                      <i class="icon-arrow-down float-right"></i>
                    </a>
                  </h6>
                </div>

                <div id="collapse2" class="collapse">
                  <div class="card-body">
                    <i class="icon-cloud-download"></i>&nbsp;<a
                      style="cursor: pointer;"
                      @click="loadDocumentMobile"
                      >Download</a
                    >
                    <div class="line-title-left" style="margin-top: 5px;"></div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="faq" class="bg-light">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <!-- Heading Text  -->
            <div class="section-heading text-center">
              <h2>Download Biosoft Install.</h2>
              <div class="line-title-center"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <div id="accordion" role="tablist">
              <div class="card">
                <div class="card-header heading" role="tab">
                  <h6 class="mb-0">
                    <a
                      href="#install_1"
                      data-parent="#accordion"
                      data-toggle="collapse"
                    >
                      1. Biosoft Server
                      <i class="icon-arrow-down float-right"></i>
                    </a>
                  </h6>
                </div>

                <div id="install_1" class="collapse">
                  <div class="card-body">
                    <i class="icon-cloud-download"></i>&nbsp;<a
                      style="cursor: pointer;"
                      @click="loadSQLServer"
                      >1.1 Microsoft SQL Server 2008 R2 Express</a
                    >
                    <div class="line-title-left" style="margin-top: 5px;"></div>
                  </div>
                  <div class="card-body">
                    <i class="icon-cloud-download"></i>&nbsp;<a
                      style="cursor: pointer;"
                      @click="loadSQLSysClrTypes"
                      >1.2 SQLSysClrTypes</a
                    >
                    <div class="line-title-left" style="margin-top: 5px;"></div>
                  </div>
                  <div class="card-body">
                    <i class="icon-cloud-download"></i>&nbsp;<a
                      style="cursor: pointer;"
                      @click="loadCR13SP25MSI64"
                      >1.3 CR13SP25MSI64</a
                    >
                    <div class="line-title-left" style="margin-top: 5px;"></div>
                  </div>
                  <div class="card-body">
                    <i class="icon-cloud-download"></i>&nbsp;<a
                      style="cursor: pointer;"
                      @click="loadSQLBackup"
                      >1.4 SQL Backup Master</a
                    >
                    <div class="line-title-left" style="margin-top: 5px;"></div>
                  </div>
                  <div class="card-body">
                    <i class="icon-cloud-download"></i>&nbsp;<a
                      style="cursor: pointer;"
                      @click="loadSSMS"
                      >1.5 SQL Server Management Studio</a
                    >
                    <div class="line-title-left" style="margin-top: 5px;"></div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header heading" role="tab">
                  <h6 class="mb-0">
                    <a
                      href="#install_2"
                      data-parent="#accordion"
                      data-toggle="collapse"
                    >
                      2. Biosoft Client
                      <i class="icon-arrow-down float-right"></i>
                    </a>
                  </h6>
                </div>

                <div id="install_2" class="collapse">
                  <div class="card-body">
                    <i class="icon-cloud-download"></i>&nbsp;<a
                      style="cursor: pointer;"
                      @click="loadBiosoft"
                      >2.1 Biosoft</a
                    >
                    <div class="line-title-left" style="margin-top: 5px;"></div>
                  </div>
                  <div class="card-body">
                    <i class="icon-cloud-download"></i>&nbsp;<a
                      style="cursor: pointer;"
                      @click="loadReport64"
                      >2.2 CRRedist2005_X64</a
                    >
                    <div class="line-title-left" style="margin-top: 5px;"></div>
                  </div>
                  <div class="card-body">
                    <i class="icon-cloud-download"></i>&nbsp;<a
                      style="cursor: pointer;"
                      @click="loadReport32"
                      >2.3 CRRedist2005_x86</a
                    >
                    <div class="line-title-left" style="margin-top: 5px;"></div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header heading" role="tab">
                  <h6 class="mb-0">
                    <a
                      href="#install_3"
                      data-parent="#accordion"
                      data-toggle="collapse"
                    >
                      3. Add On
                      <i class="icon-arrow-down float-right"></i>
                    </a>
                  </h6>
                </div>
                <div id="install_3" class="collapse">
                  <div class="card-body">
                    <i class="icon-cloud-download"></i>&nbsp;<a
                      style="cursor: pointer;"
                      @click="loadAnydesk"
                      >3.1 Anydesk</a
                    >
                    <div class="line-title-left" style="margin-top: 5px;"></div>
                  </div>
                  <div class="card-body">
                    <i class="icon-cloud-download"></i>&nbsp;<a
                      style="cursor: pointer;"
                      @click="load7ZIP"
                      >3.2 7-Zip</a
                    >
                    <div class="line-title-left" style="margin-top: 5px;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "app",
  methods: {
    loadDocument() {
      window.open(
        "https://docs.google.com/uc?export=download&id=16M0jzMbZhmVvrq1_gS6hDs6XLyEzNlM0",
        "_blank"
      );
    },
    loadDocumentMobile() {
      window.open(
        "https://docs.google.com/uc?export=download&id=1Hve8jzP3sDkHZCcYTSaVVfcqHPNqwLR3",
        "_blank"
      );
    },
    loadSQLServer() {
      window.open(
        "https://docs.google.com/uc?export=download&id=1X1Nf8mt5qpKK3VasjJupyWR_DnSX0UUG",
        "_blank"
      );
    },
    loadSQLBackup() {
      window.open(
        "https://docs.google.com/uc?export=download&id=1YP534wTSL-bqLfNQT1LZNFPmBUOOGyA5",
        "_blank"
      );
    },
    loadReport32() {
      window.open(
        "https://docs.google.com/uc?export=download&id=1R0kjRpfTl-5_-3A76Bpi-soGWVOqzVYA",
        "_blank"
      );
    },
    loadReport64() {
      window.open(
        "https://docs.google.com/uc?export=download&id=15ug8WqxX5b0QbP0XQBzLaHjSO8fqazaV",
        "_blank"
      );
    },
    loadBiosoft() {
      window.open(
        "https://docs.google.com/uc?export=download&id=1EgkjO9LpyC8voyBySvkODn0RIgIAZDXA",
        "_blank"
      );
    },
    loadSQLSysClrTypes() {
      window.open(
        "https://docs.google.com/uc?export=download&id=1yIRds-QNCrS-MBx7kzg6yx31dxHuqH7K",
        "_blank"
      );
    },
    loadCR13SP25MSI64() {
      window.open(
        "https://docs.google.com/uc?export=download&id=1vu1dw2iG_H_oM4agcU2vDYCamW80xIeA",
        "_blank"
      );
    },
    loadSSMS() {
      window.open("https://aka.ms/ssmsfullsetup", "_blank");
    },
    load7ZIP() {
      window.open("https://www.7-zip.org/a/7z1900-x64.exe", "_blank");
    },
    loadAnydesk() {
      window.open("https://download.anydesk.com/AnyDesk.exe", "_blank");
    },
  },
  mounted: function() {
    this.$nextTick(function() {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style></style>
